.framer-flip-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.framer-flip-card {
    position: relative;
    width: 100%;
    transform-style: preserve-3d;
}

.framer-flip-card-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.framer-flip-card-front,
.framer-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
}