* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Below changes are implemented to fix iOS scroll issues. */
  /* -webkit-overflow-scrolling: auto; */
  /* Re-enable if previously disabled */
  /* overscroll-behavior: contain; */
}

:root {
  --Color-Greens-Background-Teal: rgba(2, 24, 30, 1);

}

body {
  font-family: "Conto", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*letter-spacing: 0.05em;
  word-spacing: 0.15em;*/
  background-color: black;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  box-sizing: border-box;
  /* overscroll-behavior-x: none;
  overscroll-behavior-y: none; */
  /* This is to enable hardware acceleration on iOS to prevent scrolling issues etc.
  transform: translate3d(0, 0, 0); */
}

#splash-styles {
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #080d1a;
}

@font-face {
  font-family: "Cursive";
  font-style: normal;
  src: local("Cursive"), url("fonts/Cursive/Playball-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Lobster";
  src: local("Lobster"), url("fonts/Cursive/Lobster-Regular.ttf") format("truetype");
  font-weight: 700;
}


@font-face {
  font-family: "Conto";
  font-style: normal;
  src: local("Conto Regular"),
    url("fonts/Conto Webfonts/conto-regular.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Conto";
  font-style: normal;
  src: local("Conto Bold"),
    url("fonts/Conto Webfonts/conto-bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Conto";
  font-style: normal;
  src: local("Conto Medium"),
    url("fonts/Conto Webfonts/conto-medium.woff2") format("woff2");
  font-weight: 500;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@keyframes springIn {
  0% {
    transform: scale(1.25);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.35;
  }

  100% {
    opacity: 1;
  }
}

@keyframes left-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes right-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes fade-in-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes themes-move-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes themes-move-down {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}